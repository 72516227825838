var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"table-filter-wrap"},[(_vm.$store.getters.getExpressPoshtaReport.length && !_vm.$store.getters.getExpressPoshtaReportLoading)?_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'shippingReport_Id',
            'shippingReport_created',
            'shippingReport_customer',
            ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table mt-0"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-lg-center"},[_vm._v(_vm._s(_vm.$t('shippingReport_Id.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('shippingReport_created.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('common_user.localization_value.value')))]),_c('th',{staticStyle:{"width":"100%"}})])]),_c('tbody',_vm._l((_vm.$store.getters.getExpressPoshtaReport),function(item,index){return _c('tr',{key:index},[_c('td',[_c('router-link',{staticClass:"table-link btn-style",attrs:{"to":item.order_edit_front_url}},[_vm._v(" "+_vm._s(item.order_id)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD MMM, YYYY"))+" ")]),_c('td',[_vm._v(_vm._s(item.customer_name))]),_c('td')])}),0)]):_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list pt-1"},_vm._l((_vm.$store.getters.getExpressPoshtaReport),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('ExpressPoshtaReportTableMobile',{attrs:{"item":item,"navTabs":_vm.navTabs,"selectedNow":_vm.selectedNow},on:{"removeItem":function($event){return _vm.$emit('removeItem', item.id)},"thermalPrint":(data) => _vm.$emit('thermalPrint', data)}})],1)}),0)]),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[(_vm.$store.getters.getExpressPoshtaReportCommonList.next_page_url !== null && _vm.$store.getters.getExpressPoshtaReport.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextExpressPoshtaReportPage},attrs:{"count":_vm.$store.getters.getExpressPoshtaReportCommonList.total - _vm.$store.getters.getExpressPoshtaReportForPage < _vm.$store.getters.getExpressPoshtaReportForPage ?
                  _vm.$store.getters.getExpressPoshtaReportCommonList.total - _vm.$store.getters.getExpressPoshtaReportForPage:
                  _vm.$store.getters.getExpressPoshtaReportForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e()],1)])]):_vm._e(),(_vm.$store.getters.getExpressPoshtaReportLoading === false && _vm.$store.getters.getExpressPoshtaReport.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }