<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row align-items-center">
        <div class="table-card__item-info">
          <router-link
              class="table-link btn-style" :to="item.order_edit_front_url">
            {{item.order_id}}
          </router-link>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('shippingReport_created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('shippingReport_customer.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.customer_name}}
            </div>
          </div>

        </div>

      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  // import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  // import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../../../../mixins/FBMMixins/FBMMixins";

  export default {
    name: "ExpressPoshtaReportTableMobile",

    components: {
      // LinkButton,
      // TableUserColumn
    },

    mixins: [
      FBMMixinsHelper
    ],

    props: {
      item: Object,
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {

    },

  }
</script>

<style lang="scss" scoped>
</style>
