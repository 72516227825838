<template>
  <div class="fragment">
    <div class="table-filter-wrap">
      <div class="site-table-wrap small-table"
           v-if="$store.getters.getExpressPoshtaReport.length && !$store.getters.getExpressPoshtaReportLoading"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'shippingReport_Id',
            'shippingReport_created',
            'shippingReport_customer',
            ])"></div>
        <table class="site-table mt-0" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th class="text-lg-center">{{$t('shippingReport_Id.localization_value.value')}}</th>
            <th>{{$t('shippingReport_created.localization_value.value')}}</th>
            <th>{{$t('common_user.localization_value.value')}}</th>
            <th style="width: 100%;"></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getExpressPoshtaReport" :key="index">
            <td>
              <router-link
                  class="table-link btn-style" :to="item.order_edit_front_url">
                {{item.order_id}}
              </router-link>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td>{{item.customer_name}}</td>
            <td></td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list pt-1">

            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getExpressPoshtaReport"
                 :key="index"
            >
              <ExpressPoshtaReportTableMobile
                  :item="item"
                  :navTabs="navTabs"
                  :selectedNow="selectedNow"
                  @removeItem="$emit('removeItem', item.id)"
                  @thermalPrint="(data) => $emit('thermalPrint', data)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getExpressPoshtaReportCommonList.next_page_url !== null && $store.getters.getExpressPoshtaReport.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextExpressPoshtaReportPage}"
                :count="$store.getters.getExpressPoshtaReportCommonList.total - $store.getters.getExpressPoshtaReportForPage < $store.getters.getExpressPoshtaReportForPage ?
                  $store.getters.getExpressPoshtaReportCommonList.total - $store.getters.getExpressPoshtaReportForPage:
                  $store.getters.getExpressPoshtaReportForPage"
            />
<!--            <ExportBtn-->
<!--                v-if="navTabs.express.active"-->
<!--                @downloadFiles="type => $emit('downloadFiles', type)"-->
<!--                class="table-bottom-btn__right"/>-->
          </div>
        </div>
      </div>


      <template v-if="$store.getters.getExpressPoshtaReportLoading === false && $store.getters.getExpressPoshtaReport.length === 0">
        <NoResult
            :countFilterParams="countFilterParams"
            :title="$t('common_noDataYet.localization_value.value')"
            :titleWithFilter="$t('common_noResult.localization_value.value')"
            :text="$t('common_noDataYetTxt.localization_value.value')"
            :textWithFilter="$t('common_noResultSeems.localization_value.value')"
            @resetFilter="$emit('resetFilter')"
        />
      </template>
      <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


    </div>
  </div>
</template>

<script>
  // import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ExpressPoshtaReportTableMobile from "./ExpressPoshtaReportTableMobile/ExpressPoshtaReportTableMobile";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  // import ExportBtn from "../../../../../../coreComponents/Buttons/Export/Export";
  // import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  // import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  // import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import {FBMMixinsHelper} from "../../../../../../../mixins/FBMMixins/FBMMixins";

  export default {
    name: "ExpressPoshtaReportTable",
    components: {
      // LinkButton,
      // ManagerButton,
      // TableUserColumn,
      ShowMore,
      ExpressPoshtaReportTableMobile,
      NoResult,
      // ExportBtn,
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [mixinDetictingMobile, FBMMixinsHelper],

    data(){
      return{
        show1: false,
        show2: false,
        show3: false,
        show4: false,
      }
    }
  }
</script>

<style lang="scss" scoped>

  td:first-child{
    text-align: center;
  }

  .site-table thead th,
  .site-table tbody td{
    padding-right: 40px;
  }

  .rank{
    display: flex;
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
    background-repeat: no-repeat;

    &.rank-1{
      background-image: url("../../../../../../../assets/img/common/rank-1.svg");
    }

    &.rank-2{
      background-image: url("../../../../../../../assets/img/common/rank-2.svg");
    }

    &.rank-3{
      background-image: url("../../../../../../../assets/img/common/rank-3.svg");
    }
  }

</style>
