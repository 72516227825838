<template>
  <div>
    <ExpressPoshtaReportHead
        :showFilter="showFilter"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
    />

    <div class="table-filter-wrap">

<!--      <ExpressPoshtaReportFilter-->
<!--          :showFilter="showFilter"-->
<!--          :filterGetParams="filterGetParams"-->
<!--          @close="closeFilter"-->
<!--          @changeFilter="data => $emit('changeFilter', data)"-->
<!--          @resetFilter="$emit('resetFilter')"-->
<!--      />-->

      <ExpressPoshtaReportTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          :navTabs="navTabs"
          :consoUsers="consoUsers"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>


import ExpressPoshtaReportHead from "./ExpressPoshtaReportHead/ExpressPoshtaReportHead";
// import ExpressPoshtaReportFilter from "./ExpressPoshtaReportFilter/ExpressPoshtaReportFilter";
import ExpressPoshtaReportTable from "./ExpressPoshtaReportTable/ExpressPoshtaReportTable";

export default {
  name: "ExpressPoshtaReportTableUser",

  components: {
    ExpressPoshtaReportHead,
    // ExpressPoshtaReportFilter,
    ExpressPoshtaReportTable,
  },

  props: {
    typeShops: String,
    countFilterParams: Number,
    filterGetParams: Object,
    navTabs: Object,
    consoUsers: Array,
  },

  data(){
    return{
      showFilter: false,
      selectedNow: false,
    }
  },

  watch: {
    filterGetParams(newVal) {
      this.filterGetParams = newVal
    },
    countFilterParams(newVal) {
      this.countFilterParams = newVal
    },
  },

  methods: {
    closeFilter() {
      this.showFilter = false
    },

    toggleFilter() {
      this.showFilter = !this.showFilter
    },

    selectAll(val) {
      this.selectedNow = val
    },
  }
}
</script>

<style scoped>

</style>
